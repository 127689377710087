import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'
import styled from 'styled-components'
import { GatsbyImage } from "gatsby-plugin-image";
import TopBtn from '../components/topBtn';

import "../assets/stylesheets/product_page.scss"

import LayoutExternal from '../components/layoutExternal'

export const productsQuery = graphql`query ProductsQuery {
  site {
    siteMetadata {
      title
      description
    }
  }
  allMarkdownRemark(filter: {frontmatter: {type: {eq: "product"}}}) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          type
          title
          order
          description
          attachments {
            publicURL
          }
          titleImage {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, quality: 20, placeholder: BLURRED)
            }
          }
          titleImageHover {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, quality: 20, placeholder: BLURRED)
            }
          }
          image {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, quality: 20, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
}
`;

const ProcedureImage = styled(GatsbyImage)`
  width: 100% !important;
  height: 100% !important;
  border-radius: 50% !important;
  position: absolute !important;
  `
const ProcedureImageTitle = styled(GatsbyImage)`
  width: 200px !important;
  height: 65px !important;
  position: absolute;
  top: 70px;
  @media (min-width: 320px) and (max-width: 767px){
    top: 55px !important;
    width: 160px !important;
    height: 50px !important;
  }
  `
const ProcedureImageTitleHover = styled(GatsbyImage)`
  width: 200px !important;
  height: 65px !important;
  position: absolute;
  top: 70px;
  filter: invert(100);
  display: none;
  z-index: 9999
  @media (min-width: 320px) and (max-width: 767px){
    top: 55px !important;
    width: 160px !important;
    height: 50px !important;
  }
  `
const HoverContainer = styled.div`
box-sizing: border-box;
display: flex;
justify-content: center;
align-items: center;
margin-top: 1rem;
margin-left: auto;
margin-right: auto;
position: relative;	
width: 350px;
height: 350px;
border-radius: 50% !important;
&:hover .procedureNoColor{
  display: inline-block !important;
}
@media (min-width: 320px) and (max-width: 767px){
  width: 300px;
  height: 300px;
}
`
const TextResp = styled.div`
  width: 75%;
  position: absolute;
  bottom: 25%;
  font-weight: bold;
  text-align: justify;
  font-size .8rem;
  color: white;
  @media (min-width: 320px) and (max-width: 767px){
    font-size .7rem;
  }
`


let Product = ({image, titleImage, titleImageHover, description, attachments}) => (
  <Col lg="6" className="mt-3 mb-3" >
    <a href={attachments.publicURL} download>
    <HoverContainer id="TooltipProduct" data-title = "Has click para descargar!" >
      <ProcedureImage  image = {image}/>
      <ProcedureImageTitle image = {titleImage}/>
      <ProcedureImageTitleHover loading="eager" image ={titleImageHover} className="procedureNoColor" />
      <TextResp>
          {description}
      </TextResp>
    </HoverContainer>
    </a>
  </Col>
)

function sortList(list) {
  return list.sort((a, b) =>  a.node.frontmatter.order - b.node.frontmatter.order);
}


export default function Services(props) {  
  const productsList = props.data.allMarkdownRemark.edges;
  //const [tooltipOpen, setTooltipOpen] = useState(false);

  //const toggle = () => setTooltipOpen(!tooltipOpen);

  sortList(productsList);
  return (
    <LayoutExternal>
        <Container className="products-page">
          <Row>
            {
              productsList.map( ({ node }, i) => (
                <Product  description = {node.frontmatter.description}  key={i}
                image = {node.frontmatter.image.childImageSharp.gatsbyImageData} 
                titleImage = {node.frontmatter.titleImage.childImageSharp.gatsbyImageData} 
                titleImageHover = {node.frontmatter.titleImageHover.childImageSharp.gatsbyImageData} 
                attachments = {node.frontmatter.attachments} />
              ))
            }
            {/**
             * <Tooltip 
            placement="auto" 
            positionFixed="false" 
            isOpen={tooltipOpen} 
            target="TooltipProduct" 
            flip="false"
            fade="false"
            toggle={toggle}
            delay={ 0 , 0 }>
              Has click para descargar!
            </Tooltip>
             */}
            
          </Row>
        </Container>
        <TopBtn showBelow={250} />
    </LayoutExternal>
  );
}